export default {
  data() {
    return {
      roomRelation: {
        loading: false,
        details: {},
        list: [],
        currentUserRooms: [],
        listLoadMore: [],
        meta: {},
        filters: {
          id: "",
          userId: "",
          name: "",
          users: {
            list: [],
            selected: [],
          },
          period: {
            selected: [],
          },
          order: "desc",
          orderBy: "id",
        },
        pagination: {
          page: 1,
          perPage: 50,
          total: 0,
          complete_list_total: 0,
          totalPages: 0,
        },
      },
    };
  },

  methods: {
    changeRoomRelationPage(current) {
      this.roomRelation.pagination.page = current;
      this.getRoomRelations();
    },
    roomRelationTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      console.log(filters, pagination);
      this.roomRelation.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.roomRelation.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getRoomRelations();
    },
    roomRelationChangePageSize(current, pageSize) {
      this.roomRelation.pagination.page = current;
      this.roomRelation.pagination.perPage = pageSize;
      this.getRoomRelations();
    },
    roomRelationFilters() {
      let filters = "",
        queryParams = new URLSearchParams("");
      history.pushState(null, null, "");

      if (this.roomRelation.filters.id) {
        filters += `&id=${this.roomRelation.filters.id}`;
      }

      if (this.roomRelation.filters.userId) {
        filters += `&user_id=${this.roomRelation.filters.userId}`;
      }

      // if (this.roomRelation.filters.users.selec) {
      //   filters += `&id=${this.roomRelation.filters.id}`;
      // }

      filters += `&order=${this.roomRelation.filters.order}&order-by=${this.roomRelation.filters.orderBy}`;

      history.replaceState(null, null, "?" + queryParams.toString());

      return filters;
    },
    async getRoomRelationById(id) {
      try {
        const response = await this.$http.get(
          `/chat-room-relation/details?id=${id}`
        );
        this.roomRelation.details = response.data;
      } catch (error) {
        console.log(error);
        this.$message.error("Houve algum erro ao carregar os dados.");
        this.roomRelation.details = [];
      }
    },
    async getRoomRelations(samePage) {
      this.roomRelation.loading = true;
      try {
        const { data } = await this.$http.get(
          `/chat-room-relation/list?page=${
            this.roomRelation.pagination.page
          }&per_page=${
            this.roomRelation.pagination.perPage
          }${this.roomRelationFilters()}`
        );

        this.roomRelation.list = data.data;

        data?.data.forEach((relation) => {
          this.roomRelation.currentUserRooms.push(relation.room_id);
        });

        this.roomRelation.currentUserRooms;
        this.roomRelation.pagination.page = data.meta.next_page;
        this.roomRelation.pagination.total = data.meta.total;

        if (!samePage) {
          this.roomRelation.pagination.page = 1;
        }
      } catch (e) {
        this.roomRelation.list = [];
        this.roomRelation.pagination.page = 0;
        this.roomRelation.pagination.total = 0;
        e;
      } finally {
        this.roomRelation.loading = false;
      }
    },
  },
};
