export default {
  data() {
    return {
      room: {
        loading: false,
        details: {},
        list: [],
        listLoadMore: [],
        meta: {},
        filters: {
          id: "",
          currentUserId: "",
          name: "",
          isArchive: false,
          users: {
            list: [],
            selected: [],
          },
          period: {
            selected: [],
          },
          order: "desc",
          orderBy: "created",
        },
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          complete_list_total: 0,
          totalPages: 0,
        },
      },
    };
  },

  methods: {
    changeRoomPage(current) {
      this.room.pagination.page = current;
      this.getRooms();
    },
    roomTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      console.log(filters, pagination);
      this.room.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.room.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getRooms();
    },
    roomChangePageSize(current, pageSize) {
      this.room.pagination.page = current;
      this.room.pagination.perPage = pageSize;
      this.getRooms();
    },
    roomFilters() {
      let filters = "";
      //  queryParams = new URLSearchParams("");
      //  history.pushState(null, null, "");

      if (this.room.filters.id) {
        filters += `&id=${this.room.filters.id}`;
      }

      if (this.room.filters.currentUserId) {
        filters += `&current_user_id=${this.room.filters.currentUserId}`;
      }

      if (this.room.filters.isArchive) {
        filters += `&is_archived=${this.room.filters.isArchive}`;
      } else {
        filters += `&is_archived=no`;
      }

      if (this.room.filters.name) {
        filters += `&room_name=${this.room.filters.name}`;
      }

      filters += `&order=${this.room.filters.order}&order-by=${this.room.filters.orderBy}`;

      //  history.replaceState(null, null, "?" + queryParams.toString());

      return filters;
    },
    async getRoomById(id) {
      try {
        const response = await this.$http.get(`/chat-room/details?id=${id}`);
        this.room.details = response.data;
      } catch (error) {
        console.log(error);
        this.$message.error("Houve algum erro ao carregar os dados.");
        this.room.details = [];
      }
    },
    async getRooms(samePage) {
      // this.room.loading = true;
      try {
        const { data } = await this.$http.get(
          `/chat-room/list?page=${this.room.pagination.page}&per_page=${
            this.room.pagination.perPage
          }${this.roomFilters()}`
        );

        this.room.list = data.data;
        this.room.pagination.page = data.meta.next_page;
        this.room.pagination.total = data.meta.total;

        if (!samePage) {
          this.room.pagination.page = 1;
        }
      } catch (e) {
        this.room.list = [];
        this.room.pagination.page = 0;
        this.room.pagination.total = 0;
        e;
      } finally {
        this.room.loading = false;
      }
    },
  },
};
