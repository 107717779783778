export default {
  data() {
    return {
      message: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 15,
          total: 0,
          totalPages: 0,
        },
        details: {},
        list: [],
        filters: {
          users: {
            list: [],
            selected: [],
          },
          sender: {
            list: [],
            selected: [],
          },
          receiver: {
            list: [],
            selected: [],
          },
          status: {
            list: [
              {
                label: "Ativo",
                value: "active",
              },
              {
                label: "Desativado",
                value: "disabled",
              },
            ],
            selected: [],
          },
          period: {
            selected: [],
          },
          searchTerm: "",
          roomId: "",
          groupBy: "",
          order: "desc",
          orderBy: "id",
        },
      },
    };
  },
  methods: {
    changeMessagesPage(current) {
      this.message.pagination.page = current;
      this.getMessages();
    },
    changeMessagesPageSize(current, pageSize) {
      this.message.pagination.page = current;
      this.message.pagination.perPage = pageSize;
      this.getMessages();
    },
    messagesTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      this.message.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.message.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "sent_at";
      this.getMessages();
    },

    messagesFilter() {
      let filters = "";

      if (this.message.filters.searchTerm) {
        filters += `&s=${this.message.filters.searchTerm}`;
      }

      if (this.message.filters.status.selected.length > 0) {
        filters += `&status=${this.message.filters.status.selected}`;
      }

      if (this.message.filters.receiver.selected.length > 0) {
        filters += `&receiver_id=${this.message.filters.receiver.selected}`;
      }

      if (this.message.filters.sender.selected.length > 0) {
        filters += `&sender_id=${this.message.filters.sender.selected}`;
      }

      if (this.message.filters.roomId) {
        filters += `&room_id=${this.message.filters.roomId}`;
      }

      if (this.message.filters.groupBy) {
        filters += `&group_by=${this.message.filters.groupBy}`;
      }

      filters += `&order=${this.message.filters.order}&order-by=${this.message.filters.orderBy}`;

      return filters;
    },
    getMessagesNoLoading() {
      console.log(
        "list messages 2",
        this.message.list,
        this.message.pagination.page
      );
      this.$http
        .get(
          `/message/list?page=${this.message.pagination.page}&per_page=${
            this.message.pagination.perPage
          }${this.messagesFilter()}`
        )
        .then(({ data }) => {
          this.message.list = [...data.data, ...this.message.list];
          this.message.pagination.page = data.meta.next_page;
        })
        .catch(({ response }) => {
          response;
        });
    },
    getMessagesIncrement(samePage) {
      this.message.loading = true;

      this.$http
        .get(
          `/message/list?page=${this.message.pagination.page}&per_page=${
            this.message.pagination.perPage
          }${this.messagesFilter()}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.message.pagination.page = 1;
          } else {
            this.message.pagination.page = data.meta.next_page;
          }
          this.message.list = data.data;
          this.message.pagination.total = data.meta.total;
          this.message.loading = false;
        })
        .catch(({ response }) => {
          this.message.list = response.data.data;
          this.message.pagination.total = response.data.meta.total;
        })
        .finally(() => {
          this.message.loading = false;
        });
    },
    getMessages(samePage) {
      this.message.loading = true;

      this.$http
        .get(
          `/message/list?page=${this.message.pagination.page}&per_page=${
            this.message.pagination.perPage
          }${this.messagesFilter()}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.message.pagination.page = 1;
          } else {
            this.message.pagination.page = data.meta.next_page;
          }
          this.message.list = data.data;
          this.message.pagination.total = data.meta.total;
          this.message.loading = false;
        })
        .catch(({ response }) => {
          this.message.list = response.data.data;
          this.message.pagination.total = response.data.meta.total;
        })
        .finally(() => {
          this.message.loading = false;
        });
    },
  },
};
