<template>
  <section class="pd-20">
    <div>
      <Chat
        ref="Chat"
        height="620px"
        :currentUserId="$store.state.userData.id"
        :messages="message.list"
        :rooms="room.list"
        :loadingRooms="room.loading"
        :showMessagesAvatar="false"
        :loadedAllMessages="loadedAllMessages"
        @onClickAddRoom="openCreateRoomModal = true"
        @onClickRoomCard="onClickRoomCard"
        @onSearchRooms="onSearchRooms"
        @onPressEnterSendMessage="onPressEnterSendMessage"
        @onScrollLoadMoreMessages="onScrollLoadMoreMessages"
        @userIsTyping="userIsTyping"
        @onClickDeleteRoom="onClickDeleteRoom"
        @onClickArchiveRoom="onClickArchiveRoom"
        @onClickChangeActiveRoom="onClickChangeActiveRoom"
        @onClickUnarchiveRoom="onClickUnarchiveRoom"
      />
    </div>

    <a-modal
      class="new-room-modal"
      v-model="openCreateRoomModal"
      title="Nova conversa"
      :width="360"
      :footer="false"
      @cancel="openCreateRoomModal = false"
    >
      <CreateNewRoomModal
        v-if="openCreateRoomModal"
        @onClickSelectUser="onClickSelectUser"
        @onClickCreateGroup="onClickCreateGroup"
      />
    </a-modal>
  </section>
</template>

<script>
//comps
import CreateNewRoomModal from "@/components/chat/modal/CreateNewRoomModal.vue";
import Chat from "@/components/chat/sections/Chat.vue";

// mixins
import messageMixins from "@/components/chat/mixins/messageMixins";
import chatRoomMixins from "@/components/chat/mixins/chatRoomMixins";
import chatRoomRelationMixins from "@/components/chat/mixins/chatRoomRelationMixins";

// img
import userAvatar from "@/assets/images/dashboard/chat/user.png";
import groupAvatar from "@/assets/images/dashboard/chat/group-chat.png";

export default {
  name: "ChatPage",
  components: { CreateNewRoomModal, Chat },
  mixins: [messageMixins, chatRoomMixins, chatRoomRelationMixins],
  data() {
    return {
      openCreateRoomModal: false,
      loadedAllMessages: false,
      scrollY: 0,
      roomActions: [
        {
          name: "archiveRoom",
          title: "Arquivar Grupo",
        },
        { name: "inviteUser", title: "Convidar usuário" },
        { name: "removeUser", title: "Remover Usuário" },
        { name: "deleteRoom", title: "Deletar Grupo" },
      ],
    };
  },
  async mounted() {
    this.roomRelation.filters.userId = this.$store.state.userData.id;
    await this.getRoomRelations();
    this.room.pagination.perPage = 600;
    this.room.filters.id = this.roomRelation.currentUserRooms;
    this.room.filters.currentUserId = this.$store.state.userData.id;
    await this.getRooms();
  },
  watch: {
    scrollY(newVal) {
      // Trigger your event here
      console.log(parseInt(newVal));
      if (parseInt(newVal) == 0) this.getMessagesNoLoading();
    },
  },
  methods: {
    onPressEnterSendMessage(message, room, messageToReply) {
      console.log("sendMessage", message, room, messageToReply);

      if (message) {
        this.$http
          .post(`/message/create`, {
            room_id: room.roomId,
            sender_id: this.$store.state.userData.id,
            is_read: false,
            reply_message_id: messageToReply?._id ?? 0,
            content: message,
          })
          .then(() => {
            this.message.pagination.page = 1;
            this.getMessages();
            this.getRooms();
          })
          .catch(() => {});
      }
    },
    onClickRoomCard(room) {
      this.loadMessages(room);
      this.loadedAllMessages = false;
    },
    onClickDeleteRoom(id) {
      this.$http.post(`/chat-room/delete?id=${id}`).then(() => {
        this.getRooms();
        this.$refs.Chat.$emit("onClickRoomCardOut", {});
      });
    },
    onClickUnarchiveRoom(id) {
      this.$http
        .post(`/chat-room/update-field`, {
          id,
          field: "is_archived",
          value: false,
        })
        .then(() => {
          this.getRooms();
        });
    },
    onClickArchiveRoom(id) {
      this.$http
        .post(`/chat-room/update-field`, {
          id,
          field: "is_archived",
          value: true,
        })
        .then(() => {
          this.getRooms();
        });
    },
    onClickChangeActiveRoom(tab) {
      console.log(tab);
      if (tab === "archived") this.room.filters.isArchive = true;
      if (tab === "active") this.room.filters.isArchive = false;

      this.room.pagination.page = 1;
      this.room.filters.id = this.roomRelation.currentUserRooms;
      this.getRooms();
    },
    loadMessages(room) {
      this.message.loading = false;
      console.log("list messages", room);

      let arr = [];

      room.users.forEach((user) => {
        arr.push(user._id);
      });

      this.message.filters.sender.selected = arr;
      this.message.filters.roomId = room.roomId;
      this.message.pagination.perPage = 10;
      this.message.pagination.page = 1;
      this.message.list = [];
      this.getMessages(true);

      setTimeout(() => {
        this.message.loading = true;
      });
    },
    onScrollLoadMoreMessages() {
      if (this.message.list.length < this.message.pagination.total) {
        this.getMessagesNoLoading();
      } else {
        this.loadedAllMessages = true;
      }
    },
    onSearchRooms(search) {
      console.log("onSearchRooms", search);

      this.room.filters.name = search;
      this.room.pagination.page = 1;
      this.room.filters.id = this.roomRelation.currentUserRooms;
      this.getRooms();
    },
    userIsTyping(message, room) {
      message, room
      // const myInterval = setInterval(() => {
      //   this.getRooms(true);
      // }, 1000);

      // console.log("userIsTyping", message, room, room.roomId);
      // setTimeout(() => {
      //   this.$http.post(`/chat-room-relation/update-field`, {
      //     field: "is_typing",
      //     user_id: this.$store.state.userData.id,
      //     room_id: room.roomId,
      //     value: true,
      //   });

      //   setTimeout(() => {
      //     this.$http.post(`/chat-room-relation/update-field`, {
      //       field: "is_typing",
      //       user_id: this.$store.state.userData.id,
      //       room_id: room.roomId,
      //       value: false,
      //     });
      //     clearInterval(myInterval);
      //   }, 5000);
      // }, 1000);
    },
    onClickCreateGroup(group, userArr) {
      this.openCreateRoomModal = false;
      console.log(group, userArr);
      userArr.push(this.$store.state.userData.id);
      this.$http
        .post(`/chat-room/create`, {
          room_name: group.name,
          avatar: group.avatar || groupAvatar,
          user_id: this.$store.state.userData.id,
          is_group: true,
        })
        .then(({ data }) => {
          userArr.forEach((id) => {
            this.$http.post(`/chat-room-relation/create`, {
              user_id: id,
              room_id: data.id,
            });
          });

          this.$http
            .post(`/chat-room/details?id=${data.id}`)
            .then(({ data }) => {
              this.$refs.Chat.$emit("onClickRoomCardOut", data);
            });

          setTimeout(() => {
            this.reloadRoomAndRelations();
          }, 200);
        });
    },
    onClickSelectUser(user) {
      this.openCreateRoomModal = false;
      console.log("onClickSelectUser", user);

      this.$http
        .post(`/chat-room/create`, {
          room_name: `${user.first_name} ${user.last_name}`,
          avatar: user.avatar || userAvatar,
          user_id: this.$store.state.userData.id,
        })
        .then(({ data }) => {
          this.$http.post(`/chat-room-relation/create`, {
            user_id: user.id,
            room_id: data.id,
          });

          this.$http.post(`/chat-room-relation/create`, {
            user_id: this.$store.state.userData.id,
            room_id: data.id,
          });

          this.$http
            .post(`/chat-room/details?id=${data.id}`)
            .then(({ data }) => {
              this.$refs.Chat.$emit("onClickRoomCardOut", data);
            });

          setTimeout(() => {
            this.reloadRoomAndRelations();
          }, 200);
        })
        .catch(() => {
          this.$message.warning("Você já tem uma conversa com este usuário.");
        });
    },
    async reloadRoomAndRelations() {
      this.roomRelation.filters.userId = this.$store.state.userData.id;
      await this.getRoomRelations();

      console.log("currentUserRooms", this.roomRelation.currentUserRooms);
      this.room.filters.id = this.roomRelation.currentUserRooms;
      await this.getRooms();
    },
  },
};
</script>

<style lang="sass">
.new-room-modal
  .ant-modal-body
    padding: 0
</style>
