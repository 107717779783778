<template>
  <div>
    <aRow type="flex" align="middle" class="chat" :style="`height: ${height}`">
      <aCol
        v-if="showRooms"
        class="rooms-wrapper"
        :style="`height: ${height}`"
        flex="345px"
      >
        <ChatRooms
          :rooms="rooms"
          :loadingRooms="loadingRooms"
          :currentUserId="currentUserId"
          @onClickAddRoom="onClickAddRoom"
          @onClickRoomCard="onClickRoomCard"
          @onSearchRooms="onSearchRooms"
          @onClickDeleteRoom="onClickDeleteRoom"
          @onClickArchiveRoom="onClickArchiveRoom"
          @onClickChangeActiveRoom="onClickChangeActiveRoom"
          @onClickUnarchiveRoom="onClickUnarchiveRoom"
        />
      </aCol>
      <aCol class="messages-wrapper" :style="`height: ${height}`" flex="auto">
        <ChatMessages
          ref="ChatMessages"
          v-if="room.roomId"
          :messages="messages"
          :room="room"
          :currentUserId="currentUserId"
          :showMessagesAvatar="showMessagesAvatar"
          :loadedAllMessages="loadedAllMessages"
          @onClickCollapseRooms="onClickCollapseRooms"
          @onPressEnterSendMessage="onPressEnterSendMessage"
          @onScrollLoadMoreMessages="onScrollLoadMoreMessages"
          @userIsTyping="userIsTyping"
        />
      </aCol>
    </aRow>
  </div>
</template>

<script>
import ChatRooms from "@/components/chat/sections/ChatRooms.vue";
import ChatMessages from "@/components/chat/sections/ChatMessages.vue";

export default {
  name: "Chat",
  props: {
    currentUserId: String, // 5656
    height: String, // 650px
    messages: Array,
    rooms: Array,
    showMessagesAvatar: Boolean, // true - false
    loadedAllMessages: Boolean,
    loadingRooms: Boolean,
  },
  components: { ChatRooms, ChatMessages },
  data() {
    return {
      showRooms: true,
      room: {},
    };
  },
  created() {
    this.$on("onClickRoomCardOut", this.onClickRoomCard);
  },
  mounted() {
    // height: 650px
  },
  methods: {
    onClickRoomCard(room) {
      console.log(room, "clicado");
      this.$emit("onClickRoomCard", room);
      this.room = false;

      setTimeout(() => {
        this.room = room;
      }, 100);
    },
    onPressEnterSendMessage(message, room, messageToReply) {
      this.$emit("onPressEnterSendMessage", message, room, messageToReply);
    },
    userIsTyping(message, room) {
      this.$emit("userIsTyping", message, room);
    },
    onClickArchiveRoom(id) {
      this.$emit("onClickArchiveRoom", id);
    },
    onClickUnarchiveRoom(id) {
      this.$emit("onClickUnarchiveRoom", id);
    },
    onClickDeleteRoom(id) {
      this.$emit("onClickDeleteRoom", id);
    },
    onClickChangeActiveRoom(tab) {
      this.$emit("onClickChangeActiveRoom", tab);
    },
    onClickAddRoom() {
      this.$emit("onClickAddRoom");
    },
    onClickCollapseRooms(show) {
      this.showRooms = show;
    },
    onScrollLoadMoreMessages() {
      this.$emit("onScrollLoadMoreMessages");
    },
    onSearchRooms(search) {
      this.$emit("onSearchRooms", search);
    },
  },
};
</script>

<style lang="sass" scoped>
.chat
  background: #fff
  border-radius: 6px
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
  .messages-wrapper
    background: #f8f9fa
    flex: 1 !important
  .rooms-wrapper
    border-right: 1px solid #eee
</style>
