var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"room-actions"},[_c('div',{staticClass:"group"},[_c('div',{on:{"click":function($event){_vm.createGroup = true}}},[_c('aRow',{attrs:{"type":"flex","justify":"start","align":"middle","gutter":10}},[_c('aCol',[_c('a-avatar',{attrs:{"size":38,"src":_vm.groupAvatar}})],1),_vm._v(" "),_c('aCol',[_c('div',{staticClass:"name"},[_vm._v("Criar grupo")]),_vm._v(" "),_c('div',{staticClass:"role"},[_vm._v("Convide um ou mais usuários")])])],1)],1),_vm._v(" "),(_vm.createGroup)?_c('span',{staticClass:"close",on:{"click":function($event){_vm.createGroup = false}}},[_c('a-icon',{attrs:{"type":"close-circle"}})],1):_vm._e()]),_vm._v(" "),(_vm.createGroup && _vm.selectedUsers.length)?_c('div',[_c('a-divider',{staticClass:"mt-10 mb-30"}),_vm._v(" "),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('HayaTextField',{attrs:{"fieldKey":"name","label":"Nome do grupo","required":true}}),_vm._v(" "),_c('div',{staticClass:"a-center"},[_c('a-button',{staticClass:"f13",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("\n            Criar grupo\n          ")])],1)],1)],1):_vm._e()]),_vm._v(" "),_c('a-divider',{staticClass:"mt-5 mb-0"}),_vm._v(" "),_c('div',{staticClass:"users-list"},[_c('a-checkbox-group',{staticStyle:{"width":"100%"},model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}},_vm._l((_vm.user.list),function({ id, first_name, last_name, avatar, role },i){return _c('div',{key:i,staticClass:"user"},[_c('aRow',{attrs:{"type":"flex","justify":"start","align":"middle","gutter":10}},[(_vm.createGroup)?_c('aCol',{attrs:{"flex":"20px"}},[_c('a-checkbox',{attrs:{"value":id}})],1):_vm._e(),_vm._v(" "),_c('aCol',{attrs:{"flex":"40px"},on:{"click":function($event){return _vm.onClickSelectUser({
                id,
                first_name,
                last_name,
                avatar,
              })}}},[(avatar)?_c('a-avatar',{attrs:{"src":avatar,"size":38}}):_c('a-avatar',{staticClass:"upper f14",staticStyle:{"color":"#f56a00","background-color":"#fde3cf"},attrs:{"size":38}},[_vm._v("\n              "+_vm._s(first_name.substring(0, 1))+_vm._s(last_name.substring(0, 1))+"\n            ")])],1),_vm._v(" "),_c('aCol',{attrs:{"flex":"auto"},on:{"click":function($event){return _vm.onClickSelectUser({
                id,
                first_name,
                last_name,
                avatar,
              })}}},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(first_name)+" "+_vm._s(last_name))]),_vm._v(" "),_c('div',{staticClass:"role"},[_vm._v(_vm._s(_vm._f("formatUserRole")(role)))])])],1)],1)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }