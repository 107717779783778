<template>
  <div>
    <div class="room-actions">
      <div class="group">
        <div @click="createGroup = true">
          <aRow type="flex" justify="start" align="middle" :gutter="10">
            <aCol>
              <a-avatar :size="38" :src="groupAvatar" />
            </aCol>
            <aCol>
              <div class="name">Criar grupo</div>
              <div class="role">Convide um ou mais usuários</div>
            </aCol>
          </aRow>
        </div>
        <span v-if="createGroup" class="close" @click="createGroup = false"
          ><a-icon type="close-circle"
        /></span>
      </div>
      <div v-if="createGroup && selectedUsers.length">
        <a-divider class="mt-10 mb-30" />

        <a-form :form="form" @submit="handleSubmit">
          <HayaTextField
            fieldKey="name"
            label="Nome do grupo"
            :required="true"
          />

          <div class="a-center">
            <a-button class="f13" type="primary" html-type="submit">
              Criar grupo
            </a-button>
          </div>
        </a-form>
      </div>
    </div>
    <a-divider class="mt-5 mb-0" />
    <div class="users-list">
      <a-checkbox-group v-model="selectedUsers" style="width: 100%">
        <div
          class="user"
          v-for="({ id, first_name, last_name, avatar, role }, i) in user.list"
          :key="i"
        >
          <aRow type="flex" justify="start" align="middle" :gutter="10">
            <aCol flex="20px" v-if="createGroup">
              <a-checkbox :value="id" />
            </aCol>
            <aCol
              @click="
                onClickSelectUser({
                  id,
                  first_name,
                  last_name,
                  avatar,
                })
              "
              flex="40px"
            >
              <a-avatar v-if="avatar" :src="avatar" :size="38" />
              <a-avatar
                :size="38"
                v-else
                class="upper f14"
                style="color: #f56a00; background-color: #fde3cf"
              >
                {{ first_name.substring(0, 1) }}{{ last_name.substring(0, 1) }}
              </a-avatar>
            </aCol>
            <aCol
              flex="auto"
              @click="
                onClickSelectUser({
                  id,
                  first_name,
                  last_name,
                  avatar,
                })
              "
            >
              <div class="name">{{ first_name }} {{ last_name }}</div>
              <div class="role">{{ role | formatUserRole }}</div>
            </aCol>
          </aRow>
        </div>
      </a-checkbox-group>
    </div>
  </div>
</template>

<script>
import userMixins from "@/mixins/user/userMixins";
import HayaTextField from "@/components/general/fields/HayaTextField.vue";
import groupAvatar from "@/assets/images/dashboard/chat/group-chat.png";

export default {
  components: { HayaTextField },
  name: "CreateNewRoomModal",
  mixins: [userMixins],
  data() {
    return {
      form: this.$form.createForm(this),
      groupAvatar,
      loadingMore: false,
      showLoadingMore: true,
      createGroup: false,
      selectedUsers: [],
    };
  },
  mounted() {
    this.user.filters.status.selected = [1];
    this.user.pagination.perPage = 100;
    this.user.filters.notUsers.selected = [2];
    this.getUsers();
  },
  filters: {
    formatUserRole(role) {
      if (role == "admin") {
        role = "Administrador";
      }
      if (role == "user") {
        role = "Consultor";
      }
      if (role == "manager") {
        role = "Gestor";
      }

      if (role == "miles-manager") {
        role = "Gestor de Milhas";
      }

      if (role == "financial") {
        role = "Financeiro";
      }

      return role;
    },
  },
  methods: {
    onClickSelectUser(user) {
      if (!this.createGroup) this.$emit("onClickSelectUser", user);
    },
    onLoadMore() {
      this.loadingMore = true;
      this.getUsers();
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(err, values);
        values.avatar = "";
        if (!err) {
          this.$emit("onClickCreateGroup", values, this.selectedUsers);
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.room-actions
  padding: 10px
  .group
    position: relative
    padding: 7px 5px
    border-radius: 6px
    transition: .3s
    cursor: pointer,
    .close
      position: absolute
      right: 10px
      font-size: 16px
      top: 15px
      color: #bbb
    .name
      font-size: 14px
      color: #333
      font-weight: 500
    .role
      font-size: 12px
      color: #aaa
    &:hover
      background: #fafafa
.users-list
  height: 400px
  overflow-y: auto
  overflow-x: hidden
  padding: 10px
  .user
    margin-bottom: 4px
    padding: 7px 5px
    border-radius: 6px
    transition: .3s
    cursor: pointer
    .name
      font-size: 13px
      color: #333
      font-weight: 500
    .role
      font-size: 11px
      color: #aaa
    &:hover
      background: #fafafa
</style>
